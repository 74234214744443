import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { ProductSet } from 'utils/types/products';
import { ActionType } from 'components/shared/actionsCell/ActionsCell.consts';
import { TableProps } from 'components/shared/table/Table.consts';
import ActionsCell from 'components/shared/actionsCell/ActionsCell';
import CellTitleWrapper from 'components/shared/table/components/cellTitleWrapper/CellTitleWrapper';

export const getProductSetsTableProps = (
  onActionClick: (actionType: ActionType, set: ProductSet) => void,
): TableProps => ({
  columns: [
    {
      field: 'name',
      headerName: 'Set Name',
      width: 460,
      renderCell: (params: GridCellParams) => {
        return <CellTitleWrapper title={params.value as string} />;
      },
    },
    {
      field: 'amount',
      headerName: 'Product Amount',
      width: 230,
      valueGetter: ({ row }) => (row as ProductSet).products?.length,
      sortable: true,
    },
    {
      field: '',
      headerName: '',
      width: 100,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { id, row } = params;
        return (
          <div id={`Actions-${id}`} style={{ display: 'none' }}>
            <ActionsCell
              actionTypes={[ActionType.View, ActionType.Edit]}
              onActionClick={(actionType: ActionType) => onActionClick(actionType, row as ProductSet)}
            />
          </div>
        );
      },
    },
  ],
  rows: [],
  sortingMode: 'server',
  sortingOrder: ['desc', 'asc'],
});
