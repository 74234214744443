import {
  createNewCampaign,
  createNewCampaigns,
  setCampaignApprovalStatus,
  updateCampaign,
  deployCampaignById,
} from 'utils/api/campaigns';
import { ApprovalStatus } from 'utils/types';
import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';
import { MessageType } from 'components/shared/notifications/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { CampaignCreationProps, CampaignProps } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { orderBy } from 'lodash';
import { duplicateItemString, inProcessErrorMessage } from 'pages/shared/shared.consts';

const inProcessErrorHandler = () => {
  showToast(MessageType.Error, `Campaign cannot be modified at this time as campaign approval is in progress`);
  store.dispatch(closeModal());
};

export const createCampaign = async (campaign: CampaignProps) => {
  try {
    await createNewCampaign(campaign);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Campaign added successfully`);
  } catch (e) {
    const errorMessage = e.message.includes(duplicateItemString)
      ? `Failed to add campaign - ${campaign.title} already exists`
      : 'Failed to add campaign';
    showToast(MessageType.Error, errorMessage);
  }
};

export const createCampaigns = async (campaign: CampaignCreationProps) => {
  try {
    const {
      data: { createCampaigns: createCampaignsResponse },
    } = await createNewCampaigns(campaign);
    store.dispatch(closeModal());
    if (createCampaignsResponse.isProcessed === false) {
      showToast(
        MessageType.Info,
        `Your campaign creation request has been received, we are working on it, and you'll be notified once it's ready.`,
      );
    } else {
      showToast(MessageType.Success, `Campaigns added successfully`);
    }
  } catch (e) {
    const message = e.message.includes(duplicateItemString)
      ? 'Failed to add campaigns - One or more Campaign name already exists'
      : 'Failed to create campaigns';
    showToast(MessageType.Error, message);
  }
};

export const editCampaign = async (campaign: Partial<CampaignProps>, prevAlert?: any) => {
  try {
    const {
      data: {
        updateCampaign: { alert },
      },
    } = await updateCampaign(campaign);
    store.dispatch(closeModal());
    if (!prevAlert && alert) {
      showToast(MessageType.Error, `Failed to update campaign`);
    } else {
      showToast(MessageType.Success, `Campaign updated successfully`);
    }
  } catch (e) {
    if (e.message === inProcessErrorMessage) {
      inProcessErrorHandler();
    } else {
      showToast(
        MessageType.Error,
        `Failed to update campaign${
          e.message.includes(duplicateItemString) ? ` - ${campaign.title} already exists` : ''
        }`,
      );
    }
  }
};

export const submitCampaignForApproval = async (campaign: CampaignProps) => {
  try {
    const campaignData: Partial<CampaignProps> = {
      id: campaign.id,
      isDraft: false,
    };
    await updateCampaign(campaignData);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Campaign submitted for approval successfully`);
  } catch (e) {
    if (e.message === inProcessErrorMessage) {
      inProcessErrorHandler();
    } else {
      showToast(MessageType.Error, `Failed to submit campaign for approval`);
    }
  }
};

export const archiveCampaign = async (campaign: CampaignProps, isArchive: boolean) => {
  try {
    const archivedCampaignData: Partial<CampaignProps> = {
      id: campaign.id,
      isArchive,
      isDraft: campaign.isDraft,
    };
    await updateCampaign(archivedCampaignData);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Campaign ${isArchive ? 'archived' : 'unarchived'} successfully`);
  } catch (e) {
    if (e.message === inProcessErrorMessage) {
      inProcessErrorHandler();
    } else {
      showToast(MessageType.Error, `Failed to ${isArchive ? 'archive' : 'unarchive'} campaign`);
    }
  }
};

export const approveCampaign = async (campaign: CampaignProps) => {
  try {
    const {
      data: {
        updateCampaign: { alert },
      },
    } = await setCampaignApprovalStatus(campaign.id, ApprovalStatus.Approved, campaign.approvals?.[0]?.id);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Campaign approved successfully`);
    if (alert && alert === 'deploymentFailed') {
      showToast(MessageType.Error, `Failed to deploy campaign`);
    }
  } catch (e) {
    if (e.message === inProcessErrorMessage) {
      inProcessErrorHandler();
    } else {
      showToast(MessageType.Error, `Failed to approve campaign`);
    }
  }
};

export const deployCampaign = async (campaign: CampaignProps) => {
  try {
    const {
      data: {
        deployCampaign: { alert },
      },
    } = await deployCampaignById(campaign.id);
    store.dispatch(closeModal());
    if (alert) {
      showToast(MessageType.Error, `Failed to deploy campaign`);
    } else {
      showToast(MessageType.Success, `Campaign deployed successfully`);
    }
  } catch (e) {
    showToast(MessageType.Error, `Failed to deploy campaign`);
  }
};

export const rejectCampaign = async (campaign: CampaignProps, rejectionComment: string) => {
  try {
    await setCampaignApprovalStatus(
      campaign.id,
      ApprovalStatus.Rejected,
      campaign.approvals?.[0]?.id,
      rejectionComment,
    );
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Campaign rejected successfully`);
  } catch (e) {
    if (e.message === inProcessErrorMessage) {
      inProcessErrorHandler();
    } else {
      showToast(MessageType.Error, `Failed to reject campaign`);
    }
  }
};

export const revokeCampaign = async (campaign: CampaignProps) => {
  try {
    const {
      data: {
        updateCampaign: { alert },
      },
    } = await setCampaignApprovalStatus(
      campaign.id,
      ApprovalStatus.Revoked,
      campaign.approvals.length ? orderBy(campaign.approvals, ['id'], ['desc'])[0]?.id : undefined,
    );
    store.dispatch(closeModal());
    if (alert) {
      showToast(MessageType.Error, `Failed to revoke campaign`);
    } else {
      showToast(MessageType.Success, `Campaign revoked successfully`);
    }
  } catch (e) {
    showToast(MessageType.Error, `Failed to revoke campaign`);
  }
};

export const stopCampaignAssociation = async (campaign: CampaignProps) => {
  try {
    const {
      data: {
        updateCampaign: { alert },
      },
    } = await setCampaignApprovalStatus(campaign.id, ApprovalStatus.AssociationStopped, campaign.approvals?.[0]?.id);
    store.dispatch(closeModal());
    if (alert) {
      showToast(MessageType.Error, `Failed to stop association`);
    } else {
      showToast(MessageType.Success, `Association stopped successfully`);
    }
  } catch (e) {
    showToast(MessageType.Error, `Failed to stop association`);
  }
};
