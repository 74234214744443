import React, { useEffect } from 'react';
import { FormMode } from 'utils/types';
import { CampaignType, CampaignTypeLabel } from 'utils/types/campaigns';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { createCampaignTag } from 'utils/api/campaigns';
import { DistributionType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { capitalize } from 'utils/text';
import { useFormContext, useFormState } from 'react-hook-form';
import { shouldShowFieldInViewMode } from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.utils';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import VoucherConfigSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/voucherConfigSection/VoucherConfigSection';
import {
  CampaignDescription,
  CampaignName,
  FormRow,
  SectionContainer,
  StyledCheckbox,
  VoucherDistribution,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/generalDetailsSection/GeneralDetailsSection.style';
import { GeneralDetailsSectionProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/generalDetailsSection/GeneralDetailsSection.consts';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import { isInArray } from 'utils/array';
import { getIsControlWithError } from 'utils/form';
import { Feature } from 'utils/types/features';
import { FeatureGuard } from 'components/featureGuard/FeatureGuard';
import useFeatureFlag from 'hooks/use-feature-flag';
import { useQuery } from '@apollo/client';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { FetchPolicies, ValidationMessages } from 'utils/types/common';

const GeneralDetailsSection = ({ mode, isDisabled }: GeneralDetailsSectionProps) => {
  const { register, control, watch, getValues, unregister, trigger, setValue } = useFormContext();
  const { errors } = useFormState();
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Campaign]);
  const isGeneratedNameOn = useFeatureFlag(Feature.GeneratedName);
  const isNedOn = useFeatureFlag(Feature.Ned);
  const [isLocalCampaign, selectedTags, type, isGeneratedName, title, description] = watch([
    'isLocalCampaign',
    'tags',
    'type',
    'isGeneratedName',
    'title',
    'description',
    'schedule.campaignStart',
  ]);
  let arrayForAutoGenerateNames;
  if(mode === FormMode.New) {
    const { data } = useQuery(campaignsGqls.queries.getCampaignTypesForName, {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
    });
    if(data){
      arrayForAutoGenerateNames = data;
    }
  }

  let campaignTypes: any[] = Object.values(CampaignType).map((cType) => {
    return {
      id: cType,
      name: CampaignTypeLabel[cType],
    };
  });

  if (!isNedOn) {
    campaignTypes = campaignTypes.filter((campaignType: any) => campaignType.id !== CampaignType.Ned);
  }

  if (isLocalCampaign) {
    campaignTypes = campaignTypes.filter((campaignType: any) =>
      [CampaignType.LocationBased, CampaignType.Segmented].includes(campaignType.id),
    );
  }

  const onTagCreation = async (tagName: string) => {
    try {
      const tag = await createCampaignTag(tagName);
      return tag?.data?.createTag;
    } catch (e) {
      showToast(
        MessageType.Error,
        `Failed to add tag${e.message.includes('duplication item') ? ` - ${tagName} already exists in another entity. Please update tag entity or contact Admin` : ''}
        ${e.message.includes('char limit') ? ` - Exceeds 50 Character limit`: ''}`,
      );
      return null;
    }
  };

  useEffect(() => {
    if (mode === FormMode.New && isGeneratedNameOn) {
      if (isInArray([CampaignType.LocationBased, CampaignType.Segmented], type)) {
        register('isGeneratedName', { value: false, shouldUnregister: true });
      } else {
        unregister('isGeneratedName');
      }
    }
  }, [type]);

  useEffect(() => {
    if (isGeneratedName) {
      setValue('title', '');
      unregister('title');
    } else if (isGeneratedName === false) {
      register('title', {
        required: ValidationMessages.RequiredField,
        maxLength: { value: 200, message: 'Up to 200 characters' },
        shouldUnregister: true,
      });
      trigger('title');
    }
  }, [isGeneratedName]);

  useEffect(() => {
    loadTags();
  }, []);
  useEffect(() => {}, [title, description]);
  useEffect(() => {
    trigger('schedule.campaignStart');
  }, [title]);

  return (
    <SectionContainer>
      <FormRow>
        <Selectbox
          key={JSON.stringify(campaignTypes)}
          control={control}
          errors={errors}
          name="type"
          label="Campaign Type"
          placeholder="Select"
          disabled={isDisabled || getValues('offerVersion')?.templateValues?.templateType === '6'}
          validation={{
            required: ValidationMessages.RequiredField,
            validate: {
              loyalty: (value: any) => {
                if (value === CampaignType.Loyalty) {
                  const offerVersion = getValues('offerVersion');
                  if (
                    offerVersion?.translations[0]?.title.length > 100 ||
                    offerVersion?.translations[0]?.subtitle.length > 100
                  ) {
                    return 'Up to 100 characters';
                  }
                }
              },
            },
          }}
          items={campaignTypes as any[]}
          labelIsHorizontal
          selectWidth={295}
        />
        {type === CampaignType.Voucher && (
          <VoucherDistribution
            control={control}
            name="voucherConfig.distribution"
            radioList={Object.values(DistributionType).map((distributionType) => ({
              value: distributionType,
              label: capitalize(distributionType),
            }))}
            defaultValue={DistributionType.Digital}
            disabled={isDisabled}
          />
        )}
      </FormRow>
      <VoucherConfigSection isDisabled={isDisabled} mode={mode} />
      <FormRow>
        <div>
          <CampaignName
            register={register}
            errors={errors}
            name="title"
            label="Campaign Name"
            value={getValues('title')}
            placeholder={isGeneratedName ? '' : 'Enter'}
            disabled={isDisabled || isGeneratedName}
            validation={
              !isGeneratedName
                ? {
                    required: ValidationMessages.RequiredField,
                    maxLength: { value: 200, message: 'Up to 200 characters' },
                  }
                : {}
            }
            labelIsHorizontal
            isGeneratedName={isGeneratedName}
          />
          <FeatureGuard features={[{ feature: Feature.GeneratedName }]}>
            {mode === FormMode.New && isInArray(arrayForAutoGenerateNames?.getTypesFromConfig || [], type) && (
              <StyledCheckbox
                control={control}
                name="isGeneratedName"
                nameHasError={getIsControlWithError('title', errors)}
                label="VCE generated name"
                defaultValue={isGeneratedName}
              />
            )}
          </FeatureGuard>
        </div>

        {shouldShowFieldInViewMode(mode, selectedTags) && (
          <Selectbox
            key={`${Boolean(tags.length)}`}
            control={control}
            errors={errors}
            name="tags"
            label="Campaign Tags"
            placeholder="Select"
            disabled={mode === FormMode.View}
            multiple
            items={tags}
            maxItems={1}
            withSearch
            withAmount
            onCreateOption={(tagName: string) => onTagCreation(tagName)}
            labelIsHorizontal
            reset
            selectWidth={295}
            limit={10}
          />
        )}
      </FormRow>
      {shouldShowFieldInViewMode(mode, getValues('description')) && (
        <CampaignDescription
          register={register}
          errors={errors}
          name="description"
          label="Description"
          placeholder="Enter"
          disabled={mode === FormMode.View}
          validation={{
            maxLength: { value: 4000, message: 'Up to 4000 characters' },
          }}
          rows={1}
          labelIsHorizontal
        />
      )}
    </SectionContainer>
  );
};

export default GeneralDetailsSection;
