import styled from '@emotion/styled';
import typography from 'styles/typography';
import { ButtonClose } from '../button';
import { StyledChipProps } from './Chip.consts';

export const StyledChip = styled.div<StyledChipProps>`
  background-color: ${({ theme }) => theme.colors.global.background};
  border: ${({ theme }) => `1px solid ${theme.colors.global.border};`};
  border-radius: 20px;
  padding: ${({ extra, onClose }) =>
    extra || !onClose ? `0 ${typography.unit * 3}px` : `0 0 0 ${typography.unit * 3}px`};
  display: flex;
  cursor: default;
  min-width: ${({ extra }) => (extra ? `unset` : 0)};
  width: fit-content;
  margin-left: ${({ extra }) => (extra ? `${typography.unit}px` : 0)};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
`;

export const StyledButtonClose = styled(ButtonClose)`
  height: auto;
  padding: 0;
  margin: 0 ${typography.unit * 2}px;

  svg {
    width: 8px;
  }
`;
