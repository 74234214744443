import React from 'react';
import { toast, Slide } from 'react-toastify';
import { StyledToastContainer } from './ToastContainerWrapper.style';
import { MessageType } from '../notifications';
import Toast from '../toast/Toast';

const ToastContainerWrapper = () => {
  return (
    <StyledToastContainer
      position="top-center"
      hideProgressBar
      newestOnTop
      pauseOnFocusLoss
      pauseOnHover
      closeOnClick={false}
    />
  );
};

export const showToast = (type: MessageType, msg: string) => {
  toast(<Toast type={type}>{msg}</Toast>, {
    autoClose: type === MessageType.Error ? false : 5000,
    transition: Slide,
    toastId: msg,
  });
};

export default ToastContainerWrapper;
