import React, { useEffect } from 'react';
import { FilterIcon, Filters, StyledFilterBarSelect } from 'pages/shared/shared.style';
import { ApprovalStatus, CampaignApprovalStatusLabel, GenericEntity, OrderDirection } from 'utils/types';
import {
  CampaignAlert,
  CampaignAlertLabel,
  CampaignApprovalStatuses,
  CampaignType,
  CampaignTypeLabel,
} from 'utils/types/campaigns';
import { useSelector } from 'react-redux';
import { campaignsPage, initialState } from 'app/genericSlices/campaigns';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import useFeatureFlag from 'hooks/use-feature-flag';
import { Feature } from 'utils/types/features';
import { CampaignsFilterBarProps } from 'pages/campaigns/campaignManagement/components/campaignsFilterBar/CampaignsFilterBar.consts';
import { marketConfig } from 'app/slices/config';
import { useQuery } from '@apollo/client';
import { periodsGqls } from 'pages/settings/schedulePeriods/SchedulePeriods.gqls';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import { ButtonText } from 'components/shared/button';
import { LocationSet } from 'utils/types/locations';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import { store } from 'app/store';
import { Container, IconWrapper, StyledCheckbox, StyledSearch } from './CampaignsFilterBar.style';
import { getInitialCampaignZones, nationalSelectItem, onCampaignZoneChange } from 'utils/location';
import { FetchPolicies } from 'utils/types/common';

const CampaignsFilterBar = ({ filter, filterMulti, filterWithClear, forceStatusFilter, onSortModelChange }: CampaignsFilterBarProps) => {
  const { filters } = useSelector(campaignsPage.campaignsState);
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Campaign]);
  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[CampaignFilterType.SearchQuery]);
  const { config } = useSelector(marketConfig);
  const { data: periodsData } = useQuery(periodsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: {},
        order: { startDate: OrderDirection.DESC },
      },
    },
    skip: !config.enableSchedulePeriods,
  });
  const { data: zonesData } = useQuery<{ getLocationSets: GenericEntity<LocationSet> }>(
    locationSetsGqls.queries.getZones,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      skip: !config.enableManagementByZone,
      variables: {
        data: {
          filters: { [LocationSetsFilters.CustomSets]: false },
          order: { name: OrderDirection.ASC },
        },
      },
    },
  );
  const zoneItems = zonesData?.getLocationSets.items ? [nationalSelectItem, ...zonesData.getLocationSets.items] : [];
  const isNedOn = useFeatureFlag(Feature.Ned);
  let campaignTypes = Object.values(CampaignType).map((type) => {
    return {
      id: type,
      name: CampaignTypeLabel[type],
    };
  });

  if (!isNedOn) {
    campaignTypes = campaignTypes.filter((campaignType) => campaignType.id !== CampaignType.Ned);
  }

  useEffect(() => {
    loadTags();
  }, []);

  return (
    <Container>
      <IconWrapper>
        <FilterIcon name="filter" />
      </IconWrapper>
      <Filters>
        <StyledFilterBarSelect
          name="status"
          disabled={Boolean(forceStatusFilter)}
          placeholder="Select"
          label="Status"
          multiple
          items={
            CampaignApprovalStatuses.filter((status) => status !== ApprovalStatus.AssociationStopped).map((status) => {
              return {
                id: status,
                name: CampaignApprovalStatusLabel[status],
              };
            }) as any
          }
          onChange={(selectedItems: any) => {
            filter(
              CampaignFilterType.Status,
              Object.values(selectedItems).map((i: any) => i.id),
            );
          }}
          initialSelectedItems={forceStatusFilter ? [forceStatusFilter] : filters[CampaignFilterType.Status] ?? []}
          maxItems={1}
          selectWidth={160}
        />
        <StyledFilterBarSelect
          placeholder="Select"
          name="type"
          label="Type"
          multiple
          items={campaignTypes as any[]}
          onChange={(selectedItems: any) => {
            filter(
              CampaignFilterType.Type,
              Object.values(selectedItems).map((i: any) => i.id),
            );
          }}
          initialSelectedItems={filters[CampaignFilterType.Type] ?? []}
          reset
          maxItems={1}
          selectWidth={160}
        />
        <StyledFilterBarSelect
          name="tags"
          key={`${Boolean(tags.length)}`}
          placeholder="Select"
          label="Tags"
          multiple
          items={tags}
          onChange={(selectedItems: any) => {
            filter(
              CampaignFilterType.Tags,
              Object.values(selectedItems).map((i: any) => i.id),
            );
          }}
          initialSelectedItems={tags?.length ? filters[CampaignFilterType.Tags] ?? [] : []}
          withSearch
          withAmount
          reset
          maxItems={1}
          selectWidth={160}
        />
        <StyledFilterBarSelect
          placeholder="Select"
          label="Alert"
          name="alert"
          multiple
          items={
            Object.values(CampaignAlert).map((alert) => {
              return {
                id: alert,
                name: CampaignAlertLabel[alert],
              };
            }) as any
          }
          onChange={(selectedItems: any) => {
            filter(
              CampaignFilterType.Alert,
              Object.values(selectedItems).map((i: any) => i.id),
            );
          }}
          initialSelectedItems={filters[CampaignFilterType.Alert] ?? []}
          reset
          maxItems={1}
          selectWidth={160}
        />
        <MarketConfigurationGuard
          configurations={[{ configKey: MarketConfigurationKey.EnableSchedulePeriods, value: true }]}
        >
          {periodsData?.getPeriods?.items?.length > 0 && (
            <StyledFilterBarSelect
              placeholder="Select"
              name="periods"
              label="Periods"
              multiple
              items={periodsData.getPeriods.items}
              onChange={(selectedItems: any) => {
                filter(
                  CampaignFilterType.Period,
                  Object.values(selectedItems).map((i: any) => i.id),
                );
              }}
              initialSelectedItems={filters[CampaignFilterType.Period] ?? []}
              reset
              maxItems={1}
              selectWidth={160}
            />
          )}
        </MarketConfigurationGuard>
        <MarketConfigurationGuard
          configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
        >
          {zonesData?.getLocationSets?.items?.length > 0 && (
            <StyledFilterBarSelect
              placeholder="Select"
              name="zone"
              label="Zone"
              multiple
              items={zoneItems}
              onChange={(selectedItems: any) => {onCampaignZoneChange(selectedItems, filters, filterMulti)}}
              initialSelectedItems={getInitialCampaignZones(filters)}
              reset
              maxItems={1}
              selectWidth={160}
            />
          )}
        </MarketConfigurationGuard>
        <MarketConfigurationGuard
          configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
        >
          <StyledCheckbox
            label="Only Bulk Campaigns"
            checked={filters[CampaignFilterType.OnlyLocal] === 'true'}
            onClick={(e) => {
              filter(CampaignFilterType.OnlyLocal, filters[CampaignFilterType.OnlyLocal] !== 'true');
              e.stopPropagation();
            }}
          />
        </MarketConfigurationGuard>
        <StyledSearch
          key={`${JSON.stringify(filters[CampaignFilterType.SearchQuery])}_SearchQuery`}
          name="campaigns-search"
          caretPosition={caretPosition}
          value={filters[CampaignFilterType.SearchQuery] ?? ''}
          onChange={(e) => {
            filter(CampaignFilterType.SearchQuery, e.target.value);
            setCaretPosition(e);
            store.dispatch(
              campaignsPage.actions.setOrder(
                e.target.value?.length ? { _score: OrderDirection.DESC } : { externalId: OrderDirection.DESC },
              ),
            );
          }}
        />
        <ButtonText
          onClick={() => {
            filterWithClear(CampaignFilterType.Status, initialState.filters[CampaignFilterType.Status]);
            onSortModelChange({ externalId: OrderDirection.DESC, id: OrderDirection.DESC }, [
              { field: 'externalId', sort: 'desc' },
            ]);
          }}
        >
          Restore Defaults
        </ButtonText>
      </Filters>
    </Container>
  );
};

export default CampaignsFilterBar;
