import React, { useEffect } from 'react';
import Tooltip from 'components/shared/tooltip/Tooltip';
import ReactTooltip from 'react-tooltip';
import { ChipProps } from './Chip.consts';
import { StyledButtonClose, StyledChip } from './Chip.style';

const Chip = ({ onClose, children, title, ...rest }: ChipProps) => {
  const id = Math.random().toString(32);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      <StyledChip onClose={onClose} {...rest} data-automation-id="chip" data-for={id} data-tip>
        <span>{children}</span>
        {onClose && <StyledButtonClose onClick={onClose} />}
      </StyledChip>
      {title && <Tooltip id={id} onHover content={title} place="bottom" />}
    </>
  );
};

export default Chip;
