import { GridCellParams, GridColumns, GridFeatureMode, GridRowModel, GridSortDirection } from '@mui/x-data-grid-pro';
import React from 'react';
import { LocationSet } from 'utils/types/locations';
import ActionsCell from 'components/shared/actionsCell/ActionsCell';
import { ActionType } from 'components/shared/actionsCell/ActionsCell.consts';
import { TableProps } from 'components/shared/table/Table.consts';
import CellTitleWrapper from 'components/shared/table/components/cellTitleWrapper/CellTitleWrapper';

export const getLocationSetTableProps = (
  onActionClick: (actionType: ActionType, set: LocationSet) => void,
): TableProps => ({
  columns: [
    {
      field: 'name',
      headerName: 'Set Name',
      width: 460,
      renderCell: (params: GridCellParams) => {
        return <CellTitleWrapper title={params.value as string} />;
      },
    },
    {
      field: 'totalItems',
      headerName: 'Locations Amount',
      width: 230,
      valueGetter: ({ row }) => row.totalItems,
      sortable: true,
    },
    {
      field: '',
      headerName: '',
      width: 100,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { id, row } = params;
        const actionTypes = row.isReadOnly ? [ActionType.View] : [ActionType.View, ActionType.Edit];
        return (
          <div id={`Actions-${id}`} style={{ display: 'none' }}>
            <ActionsCell
              actionTypes={actionTypes}
              onActionClick={(actionType: ActionType) => onActionClick(actionType, row as LocationSet)}
            />
          </div>
        );
      },
    },
  ] as GridColumns,
  rows: [] as GridRowModel[],
  sortingMode: 'server' as GridFeatureMode,
  sortingOrder: ['desc', 'asc'] as GridSortDirection[],
});
