import styled from '@emotion/styled';
import typography from 'styles/typography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { StyledWithThemeProps } from 'utils/types';

export const StyledDataGridTable = styled(DataGridPro)<StyledWithThemeProps>`
  font-family: ${typography.primaryFont} !important;
  color: ${({ theme }) => theme.colors.text.primary} !important;

  div .MuiDataGrid-footerContainer {
    display: none;
  }

  .MuiDataGrid-main {
    .MuiDataGrid-viewport {
      overflow: initial;
    }

    .MuiDataGrid-columnSeparator,
    .MuiDataGrid-menuIcon {
      display: none;
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: 500;
    }

    .MuiDataGrid-row.Mui-selected {
      background-color: initial;
    }

    .MuiDataGrid-cell {
      padding: 0 ${typography.unit * 6}px;
      border-bottom: 0;

      &:focus-within,
      &:focus {
        outline: none;
      }
    }

    .MuiDataGrid-columnHeader {
      &:focus-within,
      &:focus {
        outline: none;
      }
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      padding: 0 ${typography.unit * 4}px;
    }
  }
`;
