import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import typography from 'styles/typography';
import Checkbox from 'components/shared/checkbox/Checkbox';

export const SectionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
  margin-right: ${typography.unit * 2}px;
  cursor: pointer;
  width: 18px;
`;

export const SelectOfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: absolute;
  top: 0;
  left: 0;
`;

export const SelectLocationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;
export const StyledCheckbox = styled(Checkbox)`
  margin-left: auto; 
`;