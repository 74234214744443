import { appTheme } from 'styles/themes/defaultTheme';
import { ArchiveStatus } from 'pages/settings/settings.const';
import { isInArray } from 'utils/array';

export enum ApprovalStatus {
  Draft = 'draft',
  PendingApproval = 'pendingApproval',
  Approved = 'approved',
  Deployed = 'deployed',
  Active = 'active',
  Expired = 'expired',
  AssociationStopped = 'associationStopped',
  Revoked = 'revoked',
  DeploymentFailed = 'deploymentFailed',
  Rejected = 'rejected',
  Archived = 'archived',
}

export type ApprovalStatusWithLabel = Exclude<ApprovalStatus, ApprovalStatus.AssociationStopped>;

export const ApprovalStatusLabel: Record<ApprovalStatusWithLabel, string> = {
  [ApprovalStatus.Draft]: 'Draft',
  [ApprovalStatus.PendingApproval]: 'Pending Approval',
  [ApprovalStatus.Approved]: 'Approved',
  [ApprovalStatus.Deployed]: 'Deployed',
  [ApprovalStatus.Active]: 'Active',
  [ApprovalStatus.Expired]: 'Expired',
  [ApprovalStatus.Revoked]: 'Revoked',
  [ApprovalStatus.DeploymentFailed]: 'Deployment Failed',
  [ApprovalStatus.Rejected]: 'Rejected',
  [ApprovalStatus.Archived]: 'Archived',
};

export const ImpactApprovalStatusLabel = {
  ...ApprovalStatusLabel,
  [ArchiveStatus.Unarchived]: 'Unarchived',
};

export const CampaignApprovalStatusLabel: Record<ApprovalStatus, string> = {
  ...ApprovalStatusLabel,
  [ApprovalStatus.AssociationStopped]: 'Association Stopped',
};

export interface GenericEntity<T = any> {
  quickFilterCounts?: any;
  items: T[];
  total: number;
}

export interface BaseEntity {
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

export enum OrderDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum TimeFormats {
  Until12 = '12H',
  Until24 = '24H',
}

export enum DateFormats {
  MonthDayYear = 'MM/DD/YYYY',
  DayMonthYear = 'DD/MM/YYYY',
}

export enum CalendarWeekStartsOn {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export enum UploadError {
  NoError = 0,
  InternalBucketError = 1,
  ExternalBucketError = 2,
}

export const CalendarWeekStartsOnRecord: Record<CalendarWeekStartsOn, string> = {
  [CalendarWeekStartsOn.Sunday]: 'Sun',
  [CalendarWeekStartsOn.Monday]: 'Mon',
  [CalendarWeekStartsOn.Tuesday]: 'Tue',
  [CalendarWeekStartsOn.Wednesday]: 'Wed',
  [CalendarWeekStartsOn.Thursday]: 'Thu',
  [CalendarWeekStartsOn.Friday]: 'Fri',
  [CalendarWeekStartsOn.Saturday]: 'Sat',
};

export enum Currency {
  Dollar = '$',
  Euro = '€',
  Pound = '£',
}

export interface StyledWithThemeProps {
  className?: string;
  theme?: appTheme;
}

export enum SetOfEntity {
  Products = 'products',
  Locations = 'locations',
}

export interface GenericSet<T = GenericItem> extends Partial<Record<SetOfEntity, T[]>> {
  id: number;
  originalId: number;
  name: string;
  description?: string;
  totalItems?: number;
  sets?: GenericSet<T>[];
  nonCustomParentSetId?: number;
  excludedItems?: GenericItem[];
  excludedSets?: GenericSet[];
  updatedAt?: string;
  custom: boolean;
  dummy: boolean;
  externalId: number;
  fringes?: T[];
  isReadOnly?: boolean;
  type?:string;
}

export interface GenericItem {
  id: number | string;
  name: string;
  amount?: number;
  setId?: number;
  nextStatusChangeWillBeAt?: string;
  status?: string;
}

export enum ActionType {
  Add = 'add',
  Remove = 'remove',
  AddExcluded = 'addExclude',
  RemoveExcluded = 'removeExclude',
}

export type listType = 'products' | 'sets';

export enum FormMode {
  New = 'createNew',
  View = 'view',
  Edit = 'edit',
  Duplicate = 'duplicate',
  Select = 'select',
  SelectionView = 'selectionView',
  QuickView = 'quickView',
}
export enum ProductSetType {
  Custom = 'custom',
  NonFoodProduct = 'nonfoodproduct',
}

export enum SSE {
  Campaigns = 'campaigns',
  Redemptions = 'redemptions',
  Notifications = 'notifications'
}

export const isViewMode = (mode: FormMode) =>
  isInArray([FormMode.View, FormMode.SelectionView, FormMode.QuickView], mode);

export type DateTimeConfig = Partial<
  Record<'dateFormat' | 'timeFormat' | 'startTimezone' | 'endTimezone' | 'calendarWeekStartsOn', string>
>;

export const isNumber = (val: any) => typeof val === 'number';